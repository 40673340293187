<template>
  <div class="trailerMainLayout">
    <div :class="[localDisplayLang === 'ara' ? 'trailerCardsAra' : 'trailerCards']">
      <div class="trailerCard" v-for="(trailer, index) in trailers" :key="index" @click="playTrailer(index, trailer)">
        <div class="trailerContainer">
          <div class="trailerPosterLayout">
            <img :src="trailer.poster.filelist[0].filename" class="trailerPoster" alt="" />
          </div>
          <img class="play-img" src="@/assets/icons/Ic_Play1.svg" alt="" />
          <div class="trailerContentLayout">
            <div class="trailerContentSplitter">
              <p class="trailerTitle">{{ trailer.title }}</p>
              <p class="trailerDuration">
                {{ calcTimeFormat(trailer.duration) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { vLivePlayer, vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import { mapGetters } from 'vuex';
export default {
  props: {
    contentDetails: {
      type: Object,
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      seasonNum: 1,
      seasonCount: null,
      localDisplayLang: null,
      seasonCountArray: [],
      isMpegRequired: false,
      trailers: null,
    };
  },

  computed: {
    ...mapGetters(["appConfig"]),

    checkSeries: function() {
      if (this.contentDetails.category == "MOVIE") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    console.log("this.contentDetails in trailer card", this.contentDetails);
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.trailers = this.contentDetails.trailer;
    console.log("what are the trailers=============", this.trailers);
    //this.fetchtrailerList(this.contentDetails.objectid,this.seasonNum);
    this.trailerDeeplinkPlayback();
  },
  mounted() {
    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;
        this.playerInstance.deregisterEvent("currentcontentended");
      }
    });
  },
  methods: {
    fetchtrailerList(seriesId, seasonNum) {
      console.log("fetch trailer list called from trailer called--------------", seriesId, seasonNum);
      //let pageSize = this.appConfig.defaultpageSize;
      //let pageNum = Math.floor(this.trailerList.length / pageSize) + 1;

      let payload = {
        objecttype: "CONTENT",
        seriesid: seriesId,
        seasonnum: seasonNum,
        //pagesize: pageSize,
        //page: pageNum,
        displaylanguage: this.localDisplayLang,
      };

      if (this.isMpegRequired == true) {
        payload.mpegtspackage = "YES";
      }
      eventBus.$emit("get-trailer-list", payload);
    },
    playTrailer(index = 0, singleTrailer) {
      this.contentDetails.trailerUrl = this.contentDetails.trailer[index].filelist[0].filename;

      //console.log("THE TRAILER URL----", this.content.trailerUrl);

      this.showPlayer();
      let contentList = [this.contentDetails];
      this.initTrailerPlayback(contentList);

      let locationstr = location.pathname.split("/");

      window.history.pushState(
        "object or string",
        "",
        "/" +
          locationstr[1] +
          "/" +
          locationstr[2] +
          "/" +
          locationstr[3] +
          "/trailer/" +
          singleTrailer.trailerid +
          "/play"
      );
    },
    trailerDeeplinkPlayback() {
      if (this.$route.params.trailerId && this.$route.params.isPlayBackActive == "play") {
        const filterTrailerData =
          Array.isArray(this.trailers) &&
          this.trailers.filter((trailer) => {
            if (trailer.trailerid == this.$route.params.trailerId) {
              return trailer;
            }
          });
        if (filterTrailerData.length) {
          this.contentDetails.trailerUrl = filterTrailerData[0].filelist[0].filename;
          this.showPlayer();
          let contentList = [this.contentDetails];
          this.initTrailerPlayback(contentList);
        }
      }
    },
    initTrailerPlayback(contentList) {
      this.loadContentMetaData(contentList[0], null);

      let playlist = this.buildPlayerPlaylist(contentList, true, 0);
      this.playerInstance.loadPlaylist(playlist, contentList.length);

      this.playerInstance.loadContent(0).then(() => {
        this.playerInstance.play().then(() => {
          console.log("Playback successful");
        });
      });
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },
    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      for (var aIndex = startIndex; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], isTrailer, 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },
    createPlayerPlaylistItem(content, isTrailer, contentHead) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid != undefined && content.objectid != "" ? content.objectid : "",
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: isTrailer
          ? [content.trailerUrl]
          : content.streamUrl != undefined && content.streamUrl != ""
          ? [content.streamUrl]
          : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: "noorplay",
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        isDrmContent: isTrailer ? false : content.isDrmContent,
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        position: contentHead,
				scrubbing: content.scrubbing ?content.scrubbing[0] : '',
      });

      return playlistItem;
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;

      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;

        let posterFileList = posterKey.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE").filelist;

        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL");

          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }

      return thumbNailUrl;
    },
  },
  components: {},
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.trailerMainLayout {
  margin-top: 24px;

  .trailerCards {
    margin-top: 18px;
    width: 100%;
    // max-width: 1200px;
    // margin-right: 40px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // grid-column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;

    .trailerCard {
      border-radius: 4px;
      margin-right: 55px;
      cursor: pointer;
      .trailerContainer {
        width: 250px;
        height: 190px;
        position: relative;
        transition: transform 450ms;
      }
      .trailerContainer:hover {
        z-index: 10;
        transform: scale(1.2);
        .trailerContentLayout {
          height: auto;
        }
        .episodeDescription {
          display: block;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          text-align: left;
          line-height: 15px;
          font-family: $font-regular;
          margin-bottom: 10px;
          line-height: 18px;
        }
      }
      .trailerPosterLayout {
        width: auto;
        position: absolute;
        /* for demo purpose  */
        .trailerPoster {
          width: auto;
          height: 165px;
          border-radius: 4px;
        }
      }

      .play-img {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 25%;
        opacity: 0.8;
        z-index: 9;
      }
      .trailerContentLayout {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 68%;
        opacity: 1;
        z-index: 9;
        margin: 0px; /* for demo purpose  */
        background-color: rgb(31, 30, 30);

        .trailerContentSplitter {
          display: flex;
          padding: 10px;
          justify-content: space-between;

          .trailerTitle {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 1rem;
            font-weight: 600;
          }
          .trailerDuration {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.8rem;
            opacity: 0.8;
          }
        }
      }
    }
  }
  .trailerCardsAra {
    margin-top: 18px;
    width: 100%;
    // max-width: 1200px;
    // margin-right: 40px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // grid-column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;
    margin-right: 40px;
    .trailerCard {
      border-radius: 4px;
      margin-left: 55px;
      cursor: pointer;
      .trailerContainer {
        width: 250px;
        height: 190px;
        position: relative;
        transition: transform 450ms;
      }
      .trailerContainer:hover {
        z-index: 10;
        transform: scale(1.2);
        .trailerContentLayout {
          height: auto;
        }
      }
      .trailerPosterLayout {
        width: auto;
        position: absolute;
        /* for demo purpose  */
        .trailerPoster {
          width: auto;
          height: 165px;
          border-radius: 4px;
        }
      }

      .play-img {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 25%;
        opacity: 0.8;
        z-index: 9;
      }
      .trailerContentLayout {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 68%;
        opacity: 1;
        z-index: 9;
        margin: 0px; /* for demo purpose  */
        background-color: rgb(31, 30, 30);

        .trailerContentSplitter {
          display: flex;
          padding: 10px;
          justify-content: space-between;

          .trailerTitle {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 1rem;
          }
          .trailerDuration {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.8rem;
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 576px) {
  .trailerMainLayout {
    margin-right: 0px;
    margin-left: 0px;

    .trailerCards {
      .trailerCard {
        margin-top: 40px;
        margin-right: 20px;
        .trailerPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .trailerPoster {
            width: auto;
            height: 180px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 324px;
          height: 20%;
          position: absolute;
          top: 30%;
          opacity: 0.8;
          z-index: 9;
        }
        .trailerContentLayout {
          margin-top: 45px;
          width: 324px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
  .trailerMainLayout {
    margin-right: 0px;
    margin-left: 0px;

    .trailerCards {
      .trailerCard {
        margin-right: 110px;
        margin-top: 20px;
        .trailerPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .trailerPoster {
            width: auto;
            height: 190px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 340px;
        }

        .trailerContentLayout {
          top: 80%;
          width: 340px;
        }
      }
    }
    .trailerCardsAra {
      margin-right: 20px;
      .trailerCard {
        margin-right: 0px;
        margin-left: 110px;
        margin-top: 20px;
        .trailerPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .trailerPoster {
            width: auto;
            height: 190px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 340px;
        }

        .trailerContentLayout {
          top: 80%;
          width: 340px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
  .trailerMainLayout {
    margin-right: 0px;
    margin-left: 0px;

    .trailerCards {
      .trailerCard {
        margin-right: 70px;
        margin-top: 20px;
        .trailerPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .trailerPoster {
            width: auto;
            height: 170px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 304px;
        }

        .trailerContentLayout {
          top: 80%;
          width: 304px;
        }
      }
    }
    .trailerCardsAra {
      margin-right: 20px;
      .trailerCard {
        margin-left: 70px;
        margin-top: 20px;
        .trailerPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .trailerPoster {
            width: auto;
            height: 170px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 304px;
        }

        .trailerContentLayout {
          top: 80%;
          width: 304px;
        }
      }
    }
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {
  .trailerMainLayout {
    .trailerCards {
      .trailerCard {
        margin-right: 25px;
        margin-top: 20px;
        .trailerPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .trailerPoster {
            width: auto;
            height: 145px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 260px;
        }

        .trailerContentLayout {
          top: 70%;
          width: 260px;
        }
      }
    }
    .trailerCardsAra {
      .trailerCard {
        margin-left: 25px;
        margin-top: 20px;
        .trailerPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .trailerPoster {
            width: auto;
            height: 145px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 260px;
        }

        .trailerContentLayout {
          top: 70%;
          width: 260px;
        }
      }
    }
  }
}

// @media (min-width: 1621px) and (max-width: 1720px) {
//   .mainLayout {
//     .episodeCards {
//       max-width: 1600px;
//       margin-right: 40px;
//       grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

//       .episodeCard {
//         .episodePosterLayout {
//           width: 250px;
//           position: absolute;
//           /* for demo purpose  */
//           .episodePoster {
//             max-width: 250px;
//             height: auto;
//             border-radius: 4px;
//           }
//         }
//         .episodeContentLayout {
//           top: 64%;
//           width: 250px;
//         }
//       }
//     }
//   }
// }

// @media (min-width: 1721px) and (max-width: 1920px) {
//   .mainLayout {
//     .episodeCards {
//       max-width: 1850px;
//       margin-right: 40px;
//       grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

//       .episodeCard {
//         .episodePosterLayout {
//           width: 290px;
//           position: absolute;
//           /* for demo purpose  */
//           .episodePoster {
//             max-width: 290px;
//             height: auto;
//             border-radius: 4px;
//           }
//         }
//         .episodeContentLayout {
//           top: 68%;
//           width: 290px;
//         }
//       }
//     }
//   }
// }

// @media (min-width: 1921px) {
//   .mainLayout {
//     .episodeCards {
//       max-width: 1850px;
//       margin-right: 40px;
//       grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

//       .episodeCard {
//         .episodePosterLayout {
//           width: 290px;
//           position: absolute;
//           /* for demo purpose  */
//           .episodePoster {
//             max-width: 290px;
//             height: auto;
//             border-radius: 4px;
//           }
//         }
//         .episodeContentLayout {
//           top: 68%;
//           width: 290px;
//         }
//       }
//     }
//   }
// }
// @media (min-width: 600px) {
//   .cards {
//     width: 800px;
//     grid-template-columns: repeat(2, 1fr);

//    .card{
//       //  padding: 3rem;
//       margin-right: 100px;
//       .container{

//       }
//    }
//   }
// }

// @media (min-width: 900px) {
//   .cards {
//     grid-template-columns: repeat(4, 1fr);
//   }
// }
</style>
